import React, { useState, useEffect } from "react";
import {Form, Button, Row, Col} from 'react-bootstrap'
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom'
import { useApi, getApiClient } from "../../helpers/UseApi.js";
import ReactDOM from 'react-dom'
import {ApiProvider} from 'jsonapi-react'
import { Link } from 'react-router-dom';
import FloorplanSelector from "../selectors/floorplan.component.js";

export default function CreateSvg() {
  const navigate = useNavigate();

  const [selectedFloorplan, setSelectedFloorplan] = useState("")
  const [selectedOrientation, setSelectedOrientation] = useState("Normal")
  const [selectedFloor, setSelectedFloor] = useState("First")
  const [baseSvg, setBaseSvg] = useState("")


  const [validationError,setValidationError] = useState({})

  const [developments, setDevelopments] = useState(null)
  const [floorplans, setFloorplans] = useState(null)
  const orientations = ['Normal', 'Reverse']
  const floors = ['First', 'Second', 'Third']

  const api = useApi()

  const getDevelopments = async () => {
    const {data} = await api.getDevelopments()
    setDevelopments(data)
  }

  const getFloorplans = async () => {
    const {data} = await api.getFloorplans()
    setFloorplans(data)
  }

  useEffect(() => {
    getDevelopments()
    getFloorplans()
  }, [])

  const uploadSvg = async (e) => {
    e.preventDefault();

    const svgData = {
      'floor_plan_code': selectedFloorplan,
      'orientation': selectedOrientation,
      'floor': selectedFloor,
      'svg': baseSvg
    }

    const validation = []
    setValidationError({})

    if (!selectedFloorplan) {
      validation.push('Floor Plan selection is required')
    }

    if (!baseSvg) {
      validation.push('SVG is required')
    }

    if(validation.length) {
      setValidationError(validation)
      return
    }

    const response = await api.addBaseSvg({values: svgData})
    const {data, error} = response

    if(!data){
      Swal.fire({
        text: error.title,
        icon:"error"
      })
      return
    }

    Swal.fire({
      icon:"success",
      text:"loaded successfuly"
    })

    navigate(-1)

  }

  return (
    <>
    {!floorplans && (
      <div>
      <Row>
        <Col>
          <h2>No Floorplans loaded</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Link className="btn btn-primary btn-large m-2" to={"/"}>
            Back
          </Link>
        </Col>
      </Row>
      </div>
    )}
    {floorplans && (
    <div className="container" id="uploader">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-12 col-md-6">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Upload Base SVG</h4>
              <hr />
              <div className="form-wrapper">
                {
                  Object.keys(validationError).length > 0 && (
                    <div className="row">
                      <div className="col-12">
                        <div className="alert alert-danger">
                          <ul className="mb-0">
                            {
                              Object.entries(validationError).map(([key, value])=>(
                                <li key={key}>{value}</li>
                              ))
                            }
                          </ul>
                        </div>
                      </div>
                    </div>
                  )
                }
                <Form onSubmit={uploadSvg}>
                  <Row className="my-3">
                      <Col>
                        <Form.Group controlId="floorplan">
                            <Form.Label>Floor Plan</Form.Label>
                            <FloorplanSelector floorplans={floorplans} setSelectedFloorplan={setSelectedFloorplan} selectedFloorplan={selectedFloorplan} />
                        </Form.Group>
                      </Col>
                  </Row>
                  <Row className="my-3">
                      <Col>
                        <Form.Group controlId="orienatation">
                            <Form.Label>Orientation</Form.Label>
                            <Form.Select aria-label="Orientation"
                              defaultValue={selectedOrientation}
                              onChange={(event)=>{
                                setSelectedOrientation(event.target.value)
                              }}>
                              {orientations.map((orientation)=>
                                (<option key={orientation} value={orientation}>{orientation}</option>))
                              }
                            </Form.Select>
                        </Form.Group>
                      </Col>
                  </Row>
                  <Row className="my-3">
                      <Col>
                        <Form.Group controlId="floor">
                            <Form.Label>Floor</Form.Label>
                            <Form.Select aria-label="Floor"
                              defaultValue={selectedFloor}
                              onChange={(event)=>{
                                setSelectedFloor(event.target.value)
                              }}>
                              {floors.map((floor)=>
                                (<option key={floor} value={floor}>{floor}</option>))
                              }
                            </Form.Select>
                        </Form.Group>
                      </Col>
                  </Row>
                  <Row className="my-3">
                      <Col>
                        <Form.Group controlId="SVG">
                            <Form.Label>Svg</Form.Label>
                            <Form.Control as="textarea" rows={5} value={baseSvg} onChange={(event)=>{
                              setBaseSvg(event.target.value)
                            }}/>
                        </Form.Group>
                      </Col>
                  </Row>
                  <Button variant="primary" className="m-2" block="block" type="submit">
                    Save
                  </Button>
                  <Link className="btn btn-primary btn-large m-2" to={"/"}>
                    Back
                  </Link>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )}
  </>
  )
}

const root = document.getElementById('uploader')
if (root) {
  ReactDOM.render(
    <ApiProvider client={getApiClient()}>
    </ApiProvider>,
    root
  )
}
