import React, { useState, useEffect } from "react";
import {Form, Button, Row, Col} from 'react-bootstrap'
import Swal from 'sweetalert2';
import { Link, useNavigate, useParams } from 'react-router-dom'
import {useApi, getApiClient} from "../../helpers/UseApi.js";
import ReactDOM from 'react-dom'
import {ApiProvider} from 'jsonapi-react'
import OptionForm from "./form.component.js";

export default function CreateOptionSvg() {

  const { base_id, code } = useParams()
  const navigate = useNavigate();

  const [optionCategories, setOptionCategories] = useState("")
  const [options, setOptions] = useState("")

  const api = useApi()

  const getOptionCategories = async () => {
    const {data} = await api.getOptionCategories()
    setOptionCategories(data)
  }

  const getOptions = async () => {
      const {data} = await api.getOptions({'filter[floor_plan_code]':code,'filter[base_svg_id]':base_id})
      setOptions(data)
  }

  useEffect(() => {
    getOptions()
    getOptionCategories()
  }, [])

  const [selectedOptionCategory, setSelectedOptionCategory] = useState("")
  const [selectedOption, setSelectedOption] = useState("")
  const [displayName, setDisplayName] = useState("")
  const [layer, setLayer] = useState(0)
  const [combinableFlag, setCombinableFlag] = useState(false)
  const [totalSqft, setTotalSqft] = useState(0)
  const [livableSqft, setLivableSqft] = useState(0)
  const [bedrooms, setBedrooms] = useState(0)
  const [bathrooms, setBathrooms] = useState(0)
  const [carGarage, setCarGarage] = useState(0)
  const [optionSvg, setOptionSvg] = useState("")

  const linkStyle = {
    marginLeft: "50px",
  }

  const [validationError,setValidationError] = useState({})

  const uploadSvg = async (e) => {
    e.preventDefault();

    const svgData = {
      'base_svg_id': base_id,
      'option_category_id': selectedOptionCategory,
      'option_code': selectedOption,
      'layer': layer,
      'display_name': displayName,
      'combinable': combinableFlag,
      'total_sqft': totalSqft,
      'livable_sqft': livableSqft,
      'bedrooms': bedrooms,
      'bathrooms': bathrooms,
      'car_garage': carGarage,
      'svg': optionSvg
    }

    const validation = []
    setValidationError({})

    if (!selectedOption) {
      validation.push('Option selection is required')
    }

    if (!selectedOptionCategory) {
      validation.push('Option category selection is required')
    }

    if (!displayName) {
      validation.push('Display Name is required')
    }

    if (!optionSvg) {
      validation.push('SVG is required')
    }

    if(validation.length) {
      setValidationError(validation)
      return
    }

    const response = await api.addOptionSvg({values: svgData})
    const {data, error} = response

    if(!data){
      Swal.fire({
        text: error.title,
        icon:"error"
      })
      return
    }

    Swal.fire({
      icon:"success",
      text:"loaded successfuly"
    })

    navigate(-1)

  }

  return (
    <>
    {!options && (
      <div>
      <Row>
        <Col>
          <h3>No Additional options available</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Link to={`/options/add/${code}`} className='btn btn-primary me-2'>
             Add
          </Link>
          <Button variant="primary" className="m-2" block="block"  onClick={() => navigate(-1)}>Go back</Button>
        </Col>
      </Row>
      </div>
    )}
    {options && optionCategories && (
    <div className="container" id="uploader">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-12 col-md-6">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Upload Option SVG</h4>
              <hr />
              <div className="form-wrapper">
                {
                  Object.keys(validationError).length > 0 && (
                    <div className="row">
                      <div className="col-12">
                        <div className="alert alert-danger">
                          <ul className="mb-0">
                            {
                              Object.entries(validationError).map(([key, value])=>(
                                <li key={key}>{value}</li>
                              ))
                            }
                          </ul>
                        </div>
                      </div>
                    </div>
                  )
                }
                <Form onSubmit={uploadSvg}>
                  <Row className="my-3">
                      <Col>
                        <Form.Group controlId="options">
                            <Form.Label>Option (Marks)</Form.Label>
                            <Form.Select aria-label="Option"
                              defaultValue={selectedOption}
                              onChange={(event)=>{
                                setSelectedOption(event.target.value)
                              }}>
                              <option value="">Select Option</option>
                              {options.map((option)=>
                                (<option key={option.id} value={option.code}>{option.name} - {option.code}</option>))
                              }
                            </Form.Select>
                        </Form.Group>
                      </Col>
                  </Row>
                  <OptionForm
                    optionCategories={optionCategories}
                    selectedOptionCategory={selectedOptionCategory}
                    setSelectedOptionCategory={setSelectedOptionCategory}
                    displayName={displayName}
                    setDisplayName={setDisplayName}
                    layer={layer}
                    setLayer={setLayer}
                    combinableFlag={combinableFlag}
                    setCombinableFlag={setCombinableFlag}
                    totalSqft={totalSqft}
                    setTotalSqft={setTotalSqft}
                    livableSqft={livableSqft}
                    setLivableSqft={setLivableSqft}
                    bedrooms={bedrooms}
                    setBedrooms={setBedrooms}
                    bathrooms={bathrooms}
                    setBathrooms={setBathrooms}
                    carGarage={carGarage}
                    setCarGarage={setCarGarage}
                    optionSvg={optionSvg}
                    setOptionSvg={setOptionSvg}
                  />
                  <Button variant="primary" className="m-2" block="block" type="submit">
                    Save
                  </Button>
                  <Button variant="primary" className="m-2" block="block"  onClick={() => navigate(-1)}>Go back</Button>
                  <Link to={`/options/add/${code}`} className='btn btn-primary me-2 text-right' style={linkStyle}>
                    Add
                  </Link>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )}
  </>
  )
}

const root = document.getElementById('uploader')
if (root) {
  ReactDOM.render(
    <ApiProvider client={getApiClient()}>
    </ApiProvider>,
    root
  )
}
