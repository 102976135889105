import { ApiClient, useClient} from 'jsonapi-react'
import { schemaRelationships } from './schemaRelationships'

export const NO_CACHE = { cacheTime: 0, staleTime: 0 }

export const getApiClient = () => {

    const apiURL = process.env.REACT_APP_API_URL
    const apiToken = process.env.REACT_APP_API_TOKEN

    return new ApiClient({
        url: apiURL,
        headers: {
          Authorization: `Bearer ${apiToken}`
            //'X-CSRF-TOKEN':
        },
        // Set default cache time to 5 minutes
        cacheTime: 300,
        staleTime: 60,
        // Define our schema
        schema: schemaRelationships,
    })
}

export const useApi = () => {
    const client = useClient() || getApiClient()
    return {
        clearCache: () => client.clearCache(),
        getDevelopments: () =>
            client.fetch(['developments']),
        getOptionCategories: () =>
            client.fetch(['option_categories']),
        getOptions: (filter) =>
            client.fetch(['options', filter]),
        getFloorplans: () =>
            client.fetch(['floorplans']),
        addBaseSvg: ({values}) =>
            client.mutate('base_svg', values, {
              method: 'POST',
            }),
        editBaseSvg: (basePlanId, {values}) =>
            client.mutate(['base_svg', basePlanId], values, {
              method: 'PUT',
            }),
        getBasePlans: (page) =>
            client.fetch(['base_svg', page]),
        getBasePlan: (baseplanId) =>
            client.fetch(['base_svg',baseplanId]),
        getBaseSvgWithOptions: (baseplanId) =>
            client.fetch(['base_svg',baseplanId,{include: ['option_svgs']}]),
        addOptionSvg: ({values}) =>
            client.mutate('option_svg', values, {
              method: 'POST',
            }),
        addInventory:({values}) =>
            client.mutate('inventory', values, {
              method: 'POST',
            }),
        getOptionSvgs: (filter) =>
            client.fetch(['option_svg',filter]),
        getOptionSvg: (optionSvgId) =>
            client.fetch(['option_svg',optionSvgId,{include: ['base_svg']}]),
        editOptionSvg: (optionSvgId, {values}) =>
            client.mutate(['option_svg', optionSvgId], values, {
              method: 'PUT',
            }),
        deleteOptionSvg: (optionSvgId) =>
            client.delete(['option_svg',optionSvgId]),

    }
}