import React, { useState, useEffect } from "react";
import {Form, Button, Row, Col} from 'react-bootstrap'
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom'
import { useApi, getApiClient } from "../../helpers/UseApi.js";
import ReactDOM from 'react-dom'
import {ApiProvider} from 'jsonapi-react'
import { Link } from 'react-router-dom';

export default function EditSvg() {
  const navigate = useNavigate();

  const { base_id } = useParams()

  const [baseSvg, setBaseSvg] = useState("")
  const [storedData, setStoredData] = useState("")

  const [validationError,setValidationError] = useState({})

  const api = useApi()

  const getBaseplan = async () => {
    const {data} = await api.getBasePlan(base_id)
    setBaseSvg(data.svg)
    setStoredData(data)
  }

  const clearSvg = () => {
    setBaseSvg("")
  }

  useEffect(() => {
    getBaseplan()
  }, [])


  const updateSvg = async (e) => {
    e.preventDefault();

    const updatedData = {
      'svg': baseSvg
    }

    const svgData = {...storedData, ...updatedData}

    const validation = []

    if (!baseSvg) {
      validation.push('SVG is required')
    }

    if(validation.length) {
      setValidationError(validation)
      return
    }

    const response = await api.editBaseSvg(base_id, {values: svgData})
    const {data, error} = response

    if(!data){
      Swal.fire({
        text: error.title,
        icon:"error"
      })
      return
    }

    Swal.fire({
      icon:"success",
      text:"updated successfuly"
    })

    navigate(-1)

  }

  return (
    <>
    {storedData && (
    <div className="container" id="uploader">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-12 col-md-6">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Edit Base SVG</h4>
              <hr />
              <div className="form-wrapper">
                {
                  Object.keys(validationError).length > 0 && (
                    <div className="row">
                      <div className="col-12">
                        <div className="alert alert-danger">
                          <ul className="mb-0">
                            {
                              Object.entries(validationError).map(([key, value])=>(
                                <li key={key}>{value}</li>
                              ))
                            }
                          </ul>
                        </div>
                      </div>
                    </div>
                  )
                }
                <Form onSubmit={updateSvg}>
                  <Row className="my-3">
                      <Col>
                        <Form.Group controlId="SVG">
                            <Form.Label>Svg</Form.Label>
                            <Form.Control as="textarea" rows={5} value={baseSvg} onChange={(event)=>{
                              setBaseSvg(event.target.value)
                            }}/>
                        </Form.Group>
                      </Col>
                  </Row>
                  <Button variant="primary" className="m-2" block="block" type="submit">
                    Save
                  </Button>
                  <Link className="btn btn-primary btn-large m-2" to={"/"}>
                    Back
                  </Link>
                  <Button variant="primary" className="m-2" block="block" onClick={clearSvg}>
                    Clear
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )}
  </>
  )
}

const root = document.getElementById('uploader')
if (root) {
  ReactDOM.render(
    <ApiProvider client={getApiClient()}>
    </ApiProvider>,
    root
  )
}
