import React, { useState, useEffect } from "react";
import {Form, Button, Row, Col} from 'react-bootstrap'
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom'
import { useApi, getApiClient } from "../../helpers/UseApi.js";
import ReactDOM from 'react-dom'
import {ApiProvider} from 'jsonapi-react'
import Select from 'react-select'

export default function AddOption() {

  const { code } = useParams()
  const navigate = useNavigate();
  const [optionsOptions, setOptionsOptions] = useState("")
  const [developmentOptions, setDevelopmentOptions] = useState("")

  const api = useApi()

  const getDevelopments = async () => {
    const {data} = await api.getDevelopments()
    setDevelopmentOptions(
          data?.map((development) =>
            ({value: development.code, label: development.name})
          )
    )
  }

  const getOptions = async () => {
      const {data} = await api.getOptions({'filter[standard]':true})
      setOptionsOptions(
        data?.map((option) =>
          ({value: option.code, label: option.name + ' - ' + option.code})
        )
      )
  }

  useEffect(() => {
    getDevelopments()
    getOptions()
  }, [])

  const [selectedOption, setSelectedOption] = useState("")
  const [selectedDevelopments, setSelectedDevelopment] = useState("")

  const [validationError,setValidationError] = useState({})

  const addInventory = async (e) => {
     e.preventDefault();

    const validation = []
    setValidationError({})

    if (!selectedOption) {
       validation.push('Option selection is required')
    }

    if (Object.keys(selectedDevelopments).length === 0) {
      validation.push('Development selection is required')
    }

    if(validation.length) {
      setValidationError(validation)
      return
    }

    const inventoryData = {
      'floor_plan_code': code,
      'option_code': selectedOption,
      'manual': true,
    }

    for (var development of selectedDevelopments) {

      const inventory = {'development_code': development.value, ...inventoryData}
      const response = await api.addInventory({values: inventory})
      const {data, error} = response

      if(!data){
        Swal.fire({
          text: error.title,
          icon:"error"
        })
        return
      }
    }

    Swal.fire({
      icon:"success",
      text:"loaded successfuly"
    })

    navigate(-1)
  }

  return (
    <>
    {developmentOptions && optionsOptions && (
    <div className="container" id="uploader">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-12 col-md-6">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Add Manual Option</h4>
              <hr />
              <div className="form-wrapper">
                {
                  Object.keys(validationError).length > 0 && (
                    <div className="row">
                      <div className="col-12">
                        <div className="alert alert-danger">
                          <ul className="mb-0">
                            {
                              Object.entries(validationError).map(([key, value])=>(
                                <li key={key}>{value}</li>
                              ))
                            }
                          </ul>
                        </div>
                      </div>
                    </div>
                  )
                }
                <Form onSubmit={addInventory}>
                  <Row className="my-3">
                      <Col>
                        <Form.Group controlId="options">
                            <Form.Label>Option (Marks Stadndard)</Form.Label>
                            <Select options={optionsOptions} onChange={(event)=>{setSelectedOption(event.value)}} />
                        </Form.Group>
                      </Col>
                  </Row>
                  <Row className="my-3">
                      <Col>
                        <Form.Group controlId="development">
                            <Form.Label>Development</Form.Label>
                            <Select isMulti={true} options={developmentOptions} onChange={(event)=>{setSelectedDevelopment(event)}}  className="basic-multi-select"/>
                        </Form.Group>
                      </Col>
                  </Row>

                  <Button variant="primary" className="m-2" block="block" type="submit">
                    Save
                  </Button>
                  <Button variant="primary" className="m-2" block="block"  onClick={() => navigate(-1)}>Go back</Button>

                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )}
  </>
  )
}

const root = document.getElementById('uploader')
if (root) {
  ReactDOM.render(
    <ApiProvider client={getApiClient()}>
    </ApiProvider>,
    root
  )
}
