import React, {useState, useEffect, createRef } from "react";
import { useParams } from 'react-router-dom'
import { useApi } from "../../helpers/UseApi.js";
import { Link } from 'react-router-dom';
import { Button, Col, Row } from "react-bootstrap";

export default function ViewFullSvg() {
  const [baseSvg, setBaseSvg] = useState("")
  const [optionsSvg, setOptionsSvg] = useState("")
  const [toggle, setToggle] = useState({})

  const api = useApi()
  const {base_id} = useParams()

  const getBaseplan = async () => {
    const {data} = await api.getBaseSvgWithOptions(base_id)
    setBaseSvg(data.svg)
    setOptionsSvg(data.option_svgs)
  }

  const displaySvg = (data) => {
    return (
      toggle[data.id] && (
      <div style={{display: 'block', width: '100%', top: '0', position: 'absolute'}} key={data.id} id={data.id}>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 612 792' x='0px' y='0px' dangerouslySetInnerHTML={{__html: data.svg}}/>
      </div>
      )
    )
  }

  const makeButton = (data) => {
    let variant = toggle[data.id] ? 'primary' : 'secondary'
    return (
      <p key={data.id}>
          <Button
            variant={variant}
            onClick={() => {setToggle({...toggle, [data.id]: !toggle[[data.id]]})}}>
            {data.display_name}
          </Button>
      </p>
    )
  }

  useEffect(() => {
    getBaseplan()
  }, [])

  return (
    <>
      {optionsSvg && baseSvg && (
        <>
        <Row>
          <Col>
            <Link className="btn btn-primary btn-large m-2" to={"/"}>
              Back
            </Link>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <div style={{position:'relative'}}>
              <div style={{position: 'absolute', width: '100%'}}>
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 612 792' x='0px' y='0px' dangerouslySetInnerHTML={{__html: baseSvg}}/>
              </div>
              {optionsSvg.map(displaySvg, this)}
            </div>
          </Col>
          <Col md="2">
            {optionsSvg.map(makeButton, this)}
          </Col>
        </Row>
        </>
      )}
    </>
  )
}