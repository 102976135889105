export const schemaRelationships = {
  developments: {
    type: 'development',
  },
  floorplans: {
    type: 'development',
  },
  base_svg: {
    type: 'base_svg',
  },
}
