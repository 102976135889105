import React, { useState, useEffect } from "react";
import {Form, Button, Row, Col} from 'react-bootstrap'
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom'
import { useApi, getApiClient } from "../../helpers/UseApi.js";
import ReactDOM from 'react-dom'
import {ApiProvider} from 'jsonapi-react'
import OptionForm from "./form.component.js";

export default function EditSvg() {
  const navigate = useNavigate();

  const {option_id} = useParams()

  const [optionCategories, setOptionCategories] = useState("")

  const [selectedOptionCategory, setSelectedOptionCategory] = useState("")
  const [displayName, setDisplayName] = useState("")
  const [storedData, setStoredData] = useState("")
  const [validationError,setValidationError] = useState({})
  const [layer, setLayer] = useState("")
  const [combinableFlag, setCombinableFlag] = useState(false)
  const [totalSqft, setTotalSqft] = useState(0)
  const [livableSqft, setLivableSqft] = useState(0)
  const [bedrooms, setBedrooms] = useState(0)
  const [bathrooms, setBathrooms] = useState(0)
  const [carGarage, setCarGarage] = useState(0)
  const [optionSvg, setOptionSvg] = useState("")


  const api = useApi()

  const getOptionCategories = async () => {
    const {data} = await api.getOptionCategories()
    setOptionCategories(data)
  }

  const getOptionSvg = async () => {
    const {data} = await api.getOptionSvg(option_id)
    setOptionSvg(data.svg)
    setSelectedOptionCategory(data.option_category_id)
    setDisplayName(data.display_name)
    setCombinableFlag(data.combinable)
    setLayer(data.layer)
    setTotalSqft(data.total_sqft)
    setLivableSqft(data.livable_sqft)
    setBedrooms(data.bedrooms)
    setBathrooms(data.bathrooms)
    setCarGarage(data.car_garage)
    setStoredData(data)
  }

  const clearSvg = () => {
    setOptionSvg("")
  }

  useEffect(() => {
    getOptionSvg()
    getOptionCategories()
  }, [])


  const updateSvg = async (e) => {
    e.preventDefault();

    const updatedData = {
      'bathrooms': bathrooms,
      'bedrooms': bedrooms,
      'car_garage': carGarage,
      'combinable': combinableFlag,
      'display_name': displayName,
      'layer': layer,
      'livable_sqft': livableSqft,
      'option_category_id': selectedOptionCategory,
      'svg': optionSvg,
      'total_sqft': totalSqft,
    }

    const svgData = {...storedData, ...updatedData}

    const validation = []

    if (!selectedOptionCategory) {
      validation.push('Option category selection is required')
    }

    if (!displayName) {
      validation.push('Display Name is required')
    }

    if (!optionSvg) {
      validation.push('SVG is required')
    }

    if(validation.length) {
      setValidationError(validation)
      return
    }

    const response = await api.editOptionSvg(option_id, {values: svgData})
    const {data, error} = response

    if(!data){
      Swal.fire({
        text: error.title,
        icon:"error"
      })
      return
    }

    Swal.fire({
      icon:"success",
      text:"updated successfuly"
    })

    navigate(-1)

  }

  return (
    <>
    {storedData && optionCategories && (
    <div className="container" id="uploader">
      <div className="row justify-content-center">
        <div className="col-12 col-sm-12 col-md-6">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Edit Option SVG</h4>
              <hr />
              <div className="form-wrapper">
                {
                  Object.keys(validationError).length > 0 && (
                    <div className="row">
                      <div className="col-12">
                        <div className="alert alert-danger">
                          <ul className="mb-0">
                            {
                              Object.entries(validationError).map(([key, value])=>(
                                <li key={key}>{value}</li>
                              ))
                            }
                          </ul>
                        </div>
                      </div>
                    </div>
                  )
                }
                <Form onSubmit={updateSvg}>
                <OptionForm
                    optionCategories={optionCategories}
                    selectedOptionCategory={selectedOptionCategory}
                    setSelectedOptionCategory={setSelectedOptionCategory}
                    displayName={displayName}
                    setDisplayName={setDisplayName}
                    layer={layer}
                    setLayer={setLayer}
                    combinableFlag={combinableFlag}
                    setCombinableFlag={setCombinableFlag}
                    totalSqft={totalSqft}
                    setTotalSqft={setTotalSqft}
                    livableSqft={livableSqft}
                    setLivableSqft={setLivableSqft}
                    bedrooms={bedrooms}
                    setBedrooms={setBedrooms}
                    bathrooms={bathrooms}
                    setBathrooms={setBathrooms}
                    carGarage={carGarage}
                    setCarGarage={setCarGarage}
                    optionSvg={optionSvg}
                    setOptionSvg={setOptionSvg}
                  />
                  <Button variant="primary" className="m-2" block="block" type="submit">
                    Save
                  </Button>
                  <Button variant="primary" className="m-2" block="block"  onClick={() => navigate(-1)}>Go back</Button>
                  <Button variant="primary" className="m-2" block="block" onClick={clearSvg}>
                    Clear
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )}
  </>
  )
}

const root = document.getElementById('uploader')
if (root) {
  ReactDOM.render(
    <ApiProvider client={getApiClient()}>
    </ApiProvider>,
    root
  )
}
