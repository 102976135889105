import React, {useState, useEffect } from "react";
import { useParams } from 'react-router-dom'
import { useApi } from "../../helpers/UseApi.js";
import { Link } from 'react-router-dom';
import { Col, Row } from "react-bootstrap";

export default function ViewSvg() {
  const [baseSvg, setBaseSvg] = useState("")

  const api = useApi()
  const { base_id } = useParams()

  const getBaseplan = async () => {
    const {data} = await api.getBasePlan(base_id)
    setBaseSvg(data.svg)

  }

  useEffect(() => {
    getBaseplan()
  }, [])

  return (
    <>
      {baseSvg && (
        <>
        <Row>
          <Col>
            <Link className="btn btn-primary btn-large m-2" to={"/"}>
              Back
            </Link>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <div>
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 612 792' x='0px' y='0px' dangerouslySetInnerHTML={{__html: baseSvg}}/>
            </div>
          </Col>
        </Row>
        </>
      )}
    </>
  )
}