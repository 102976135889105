import * as React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "bootstrap/dist/css/bootstrap.css";

import { BrowserRouter as Router , Routes, Route, Link } from "react-router-dom"

import InsertBase from "./components/base/insert.component"
import BaseList from "./components/base/list.component"
import ViewBase from "./components/base/view.component"
import ViewFullBase from "./components/base/viewfull.component"
import EditBase from "./components/base/edit.component"
import InsertOption from "./components/option/insert.component"
import OptionsList from "./components/option/list.component"
import ViewOption from "./components/option/view.component"
import EditOption from "./components/option/edit.component"
import AddOption from "./components/option/add.component"

function App() {
  return (<Router>
    <Navbar bg="primary">
      <Container>
        <Link to={"/"} className="navbar-brand text-white">
          ICI Floor Plans
        </Link>
      </Container>
    </Navbar>

    <Container className="mt-5">
      <Row>
        <Col md={12}>
          <Routes>
            <Route path="/view/base/:base_id" element={<ViewBase />} />
            <Route path="/view_full/base/:base_id" element={<ViewFullBase />} />
            <Route path="/base" element={<InsertBase />} />
            <Route path="/edit/base/:base_id" element={<EditBase />} />
            <Route exact path='/' element={<BaseList />} />
            <Route path="/options/list/:base_id/:code" element={<OptionsList />} />
            <Route path="/options/new/:base_id/:code" element={<InsertOption />} />
            <Route path="/options/add/:code" element={<AddOption />} />
            <Route path="/view/option/:base_id/:option_id" element={<ViewOption />} />
            <Route path="/edit/option/:option_id" element={<EditOption />} />
          </Routes>
        </Col>
      </Row>
    </Container>
  </Router>);
}

export default App;
