import React, {useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom'
import { useApi } from "../../helpers/UseApi.js";
import { Button, Col, Row } from "react-bootstrap";

export default function ViewOptionSvg() {
  const navigate = useNavigate();
  const [optionSvg, setOptionSvg] = useState("")
  const [baseSvg, setBaseSvg] = useState("")

  const api = useApi()
  const {option_id } = useParams()

  const getOptionSvg = async () => {
    const {data} = await api.getOptionSvg(option_id)
    setOptionSvg(data.svg)
    setBaseSvg(data.base_svg.svg)
  }

  useEffect(() => {
    getOptionSvg()
  }, [])

  return (
    <>
      {baseSvg && optionSvg && (
        <>
        <Row>
          <Col>
            <Button variant="primary" className="m-2" block="block"  onClick={() => navigate(-1)}>Go back</Button>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <div>
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 612 792' x='0px' y='0px' dangerouslySetInnerHTML={{__html: baseSvg + optionSvg}}/>
            </div>
          </Col>
        </Row>
        </>
      )}
    </>
  )
}