import {Form, Row, Col} from 'react-bootstrap'
import React, {useState} from "react";

const OptionForm = ({
  optionCategories,
  selectedOptionCategory,
  setSelectedOptionCategory,
  displayName,
  setDisplayName,
  layer,
  setLayer,
  combinableFlag,
  setCombinableFlag,
  totalSqft,
  livableSqft,
  setTotalSqft,
  setLivableSqft,
  bedrooms,
  setBedrooms,
  bathrooms,
  setBathrooms,
  carGarage,
  setCarGarage,
  optionSvg,
  setOptionSvg,
}) => {
  return (
    <>
      <Row className="my-3">
        <Col>
          <Form.Group controlId="categories">
            <Form.Label>Option Category</Form.Label>
            <Form.Select aria-label="Option Category"
              defaultValue={selectedOptionCategory}
              onChange={(event)=>{
                setSelectedOptionCategory(event.target.value)
              }}>
              <option value="">Select Category</option>
              {optionCategories.map((optionCategory)=>
                (<option key={optionCategory.id} value={optionCategory.id}>{optionCategory.name}</option>))
              }
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <Row className="my-3">
        <Col>
          <Form.Group controlId="Name">
              <Form.Label>Display Name</Form.Label>
              <Form.Control type="text" value={displayName} onChange={(event)=>{
                setDisplayName(event.target.value)
              }}/>
          </Form.Group>
        </Col>
      </Row>
      <Row className="my-3">
      <Col xs={2}>
          <Form.Group controlId="combinable">
            <Form.Label>Combinable</Form.Label>
            <Form.Check type="switch" checked={combinableFlag} onChange={()=>{
              setCombinableFlag(!combinableFlag)
            }}/>
          </Form.Group>
        </Col>
      </Row>
      <Row className="my-3">
        <Col xs={2}>
          <Form.Group controlId="layer">
            <Form.Label>Layer</Form.Label>
            <Form.Control type="text" value={layer} onChange={(event)=>{
              setLayer(event.target.value)
            }}/>
          </Form.Group>
        </Col>
        <Col xs={2}>
          <Form.Group controlId="totalSqft">
            <Form.Label>Total Sqft</Form.Label>
            <Form.Control type="text" value={totalSqft} onChange={(event)=>{
              setTotalSqft(event.target.value)
            }}/>
          </Form.Group>
        </Col>
        <Col xs={2}>
          <Form.Group controlId="livableSqft">
            <Form.Label>Liv. Sqft</Form.Label>
            <Form.Control type="text" value={livableSqft} onChange={(event)=>{
              setLivableSqft(event.target.value)
            }}/>
          </Form.Group>
        </Col>
        <Col xs={2}>
          <Form.Group controlId="bedrooms">
            <Form.Label>Bedrooms</Form.Label>
            <Form.Control type="text" value={bedrooms} onChange={(event)=>{
              setBedrooms(event.target.value)
            }}/>
          </Form.Group>
        </Col>
        <Col xs={2}>
          <Form.Group controlId="bathrooms">
            <Form.Label>Bathrooms</Form.Label>
            <Form.Control type="text" value={bathrooms} onChange={(event)=>{
              setBathrooms(event.target.value)
            }}/>
          </Form.Group>
        </Col>
        <Col xs={2}>
          <Form.Group controlId="cargarage">
            <Form.Label>Garage</Form.Label>
            <Form.Control type="text" value={carGarage} onChange={(event)=>{
              setCarGarage(event.target.value)
            }}/>
          </Form.Group>
        </Col>
      </Row>
      <Row className="my-3">
        <Col>
          <Form.Group controlId="SVG">
            <Form.Label>Svg</Form.Label>
            <Form.Control as="textarea" rows={5} value={optionSvg} onChange={(event)=>{
              setOptionSvg(event.target.value)
            }}/>
          </Form.Group>
        </Col>
      </Row>
    </>
  )
}

export default OptionForm