import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useApi, getApiClient } from "../../helpers/UseApi.js";
import {ApiProvider} from 'jsonapi-react'
import {Table, Row, Col} from 'react-bootstrap'
import ReactDOM from 'react-dom'

export default function OptionsList() {

    const [optionSvgs, setOptionSvgs] = useState("")
    const [baseSvg, setBaseSvg] = useState("")

    useEffect(()=>{
        getOptionSvgs()
    },[])

    const api = useApi()
    const { base_id, code } = useParams()

    const getOptionSvgs = async () => {
        const {data} = await api.getOptionSvgs({'filter[base_svg_id]':base_id})
        setOptionSvgs(data)
        setBaseSvg(data ? data[0].baseSvg : "")
    }

    const deleteOption = (optionId, e) => {
      e.preventDefault()
      api.deleteOptionSvg(optionId)
      getOptionSvgs()
    }

    return (
      <div className="container" id="list">
            <Row className="my-3">
              <Col md="3">
                <Link className='btn btn-primary mb-2 float-start' to={'/'}>
                        Back
                    </Link>
              </Col>
              <Col md="6" className="text-center">
                {baseSvg && (
                  <h1>{baseSvg.floor_plan.name}: {baseSvg.orientation} {baseSvg.floor}</h1>
                )}
              </Col>
              <Col md="3">
                  <Link className='btn btn-primary mb-2 float-end' to={`/options/new/${base_id}/${code}`}>
                      Upload new Option SVG
                  </Link>
              </Col>
            </Row>
            <Row className="my-3">
                <Col>
                <div className="card card-body">
                    <div className="table-responsive">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                  <th scope="col" className="col-3">Category</th>
                                  <th scope="col" className="col-2">Option Code</th>
                                  <th scope="col" className="col-2">Display Name</th>
                                  <th scope="col" className="col-2">Combinable</th>
                                  <th scope="col" className="col-3">Actions</th>
                                </tr>
                            </thead>
                            {optionSvgs && (
                            <tbody>
                                {optionSvgs.map((row, key)=>(
                                    <tr key={key}>
                                      <td className="col-3">{row.option_category.name}</td>
                                      <td className="col-2">{row.option_code}</td>
                                      <td className="col-2">{row.display_name}</td>
                                      <td className="col-2">{String(!!row.combinable)}</td>
                                      <td className="col-3">
                                          <Link to={`/edit/option/${row.id}`} className='btn btn-success me-2'>
                                              Edit
                                          </Link>
                                          <Link to={`/view/option/${row.base_svg.id}/${row.id}`} className='btn btn-success me-2'>
                                              View
                                          </Link>
                                          <button className='btn btn-success me-2' onClick={(e) => deleteOption(row.id, e)}>
                                              Delete
                                          </button>
                                      </td>
                                    </tr>
                                  ))
                                }
                            </tbody>
                            )}
                        </Table>
                    </div>
                </div>
                </Col>
            </Row>
        </div>
    )
}

const root = document.getElementById('list')
if (root) {
  ReactDOM.render(
    <ApiProvider client={getApiClient()}>
    </ApiProvider>,
    root
  )
}