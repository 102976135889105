import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useApi, getApiClient } from "../../helpers/UseApi.js";
import {ApiProvider} from 'jsonapi-react'
import {Form, Table, Row, Col, Button} from 'react-bootstrap'
import ReactDOM from 'react-dom'
import Pagination from "react-js-pagination";
import FloorplanSelector from '../selectors/floorplan.component.js';

export default function List() {

    const [baseplans, setBasePlans] = useState("")
    const [pagination, setPagination] = useState("")
    const [floorplans, setFloorplans] = useState(null)
    const [selectedFloorplan, setSelectedFloorplan] = useState("")

    const getFloorplans = async () => {
      const {data} = await api.getFloorplans()
      setFloorplans(data)
    }

    const getBasePlans = async (pageNumber = 1) => {
      const basePlanRespone = await api.getBasePlans({'page':pageNumber, 'filter[floor_plan_code]':selectedFloorplan})
      if (!basePlanRespone?.data) {
        return
      }
      const {data, meta: {pagination}} = basePlanRespone
      setBasePlans(data)
      setPagination(pagination)
    }

    useEffect(()=>{
        getBasePlans()
        getFloorplans()
    },[])

    const api = useApi()

    return (
      <div className="container" id="list">
        <Row className="my-3">
          <Col>
            <Link className='btn btn-primary mb-2 float-end' to={"/base"}>
                Upload new Base SVG
            </Link>
          </Col>
        </Row>
        {floorplans && (
          <Row>
            <Col>
              <Form>
                <Row className="align-items-center">
                  <Col xs="3">
                    <FloorplanSelector floorplans={floorplans} setSelectedFloorplan={setSelectedFloorplan} selectedFloorplan={selectedFloorplan} />
                  </Col>
                  <Col xs="auto">
                    <Button className="mb-2" onClick={(event)=>{
                              getBasePlans()
                            }}>
                      Filter
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        )}
        <Row className="my-3">
          <Col>
            <div className="card card-body">
              <div className="table-responsive">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Floor Plan</th>
                      <th>Orienation</th>
                      <th>Floor</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  {baseplans && (
                  <tbody>
                    {baseplans.map((row, key)=>(
                      <tr key={key}>
                        <td>{row.floor_plan.name}</td>
                        <td>{row.orientation}</td>
                        <td>{row.floor}</td>
                        <td>
                          <Link to={`/edit/base/${row.id}`} className='btn btn-success me-2'>
                              Edit
                          </Link>
                          <Link to={`/view/base/${row.id}`} className='btn btn-success me-2'>
                              View
                          </Link>
                          <Link to={`/view_full/base/${row.id}`} className='btn btn-success me-2'>
                              View Full
                          </Link>
                          <Link to={`/options/list/${row.id}/${row.floor_plan_code}`} className='btn btn-success me-2'>
                              Options
                          </Link>
                        </td>
                      </tr>
                      ))
                    }
                  </tbody>
                  )}
                </Table>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="my-3">
          <Col>
            {pagination &&  (
                <Pagination
                  activePage={pagination.current_page}
                  itemsCountPerPage={pagination.per_page}
                  totalItemsCount={pagination.total}
                  onChange={(pageNumber) => {
                    getBasePlans(pageNumber)
                  }}
                  pageRangeDisplayed={8}
                  itemClass="page-item"
                  linkClass="page-link"
                  firstPageText="First Page"
                  lastPageText="Last Lage"
                />
              )}
          </Col>
        </Row>
      </div>
    )
}
const root = document.getElementById('list')
if (root) {
  ReactDOM.render(
    <ApiProvider client={getApiClient()}>
    </ApiProvider>,
    root
  )
}