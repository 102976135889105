import Select from 'react-select'

const FloorplanSelector = ({
  floorplans,
  setSelectedFloorplan,
  selectedFloorplan,
}) => {
  const floorplanOptions = floorplans?.map((floorplan) =>
    ({value: floorplan.code, label: floorplan.name})
  )
  return <Select options={floorplanOptions} onChange={(event)=>{setSelectedFloorplan(event.value)}} defaultInputValue={selectedFloorplan}/>
}

export default FloorplanSelector
